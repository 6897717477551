<template>
    <vx-card actionable class="no-gutter h-full justify-center items-center">
        <div class="items-center text-center">
            <feather-icon
                @click="show.form = !show.form"
                :icon="'CodeIcon'"
                class="p-3 inline-flex rounded-full cursor-pointer"
                :class="[`text-primary`, { 'mb-2': true }]"
                :style="{
                    background: `rgba(var(--vs-primary),.15)`,
                }"
            ></feather-icon>

            <div
                @click="show.form = !show.form"
                v-if="!show.form"
                class="mt-3 truncate text-primary cursor-pointer"
            >
                <h5 class="mb-1 font-bold text-primary">Access Code</h5>
                <small style="font-size: 1rem">Change Access Code</small>
            </div>
            <vx-input-group v-else class="text-left mt-3">
                <vs-input
                    placeholder="Code"
                    v-model="form.code"
                    @keyup.enter="checkAccessCode"
                />
                <template slot="append">
                    <div class="append-text btn-addon">
                        <vs-button
                            @click="checkAccessCode"
                            :disabled="!validForm"
                            >Go</vs-button
                        >
                    </div>
                </template>
            </vx-input-group>
            <p
                class="vs-input--label text-warning text-left"
                v-if="accessCodeError !== null && show.form"
            >
                {{ accessCodeError.detail }}
            </p>
        </div>
    </vx-card>
</template>

<script>
export default {
    name: 'AccessCodeCard',
    computed: {
        accessCode() {
            return this.$store.getters.accessCode
        },
        validForm() {
            return !!this.form.code
        },
        accessCodeError() {
            return this.$store.getters.accessCodeError
        },
    },
    data() {
        return {
            form: {
                code: null,
            },
            show: {
                form: false,
            },
        }
    },
    methods: {
        async checkAccessCode() {
            if (this.validForm) {
                this.$store.commit('accessCodeError', null)
                await this.$store
                    .dispatch('obtainAccessCode', this.form.code)
                    .then((response) => {
                        this.form.code = null
                        this.show.form = false
                        this.$vs.notify({
                            title: 'Success',
                            text: 'Access code changed',
                            color: 'success',
                            icon: 'feather',
                            iconPack: 'icon-save',
                            position: 'top-center',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            title: 'Error',
                            text: this.accessCodeError.detail,
                            color: 'warning',
                            icon: 'feather',
                            iconPack: 'icon-save',
                            position: 'top-center',
                        })
                    })
            }
        },
    },
}
</script>
