<template>
    <vx-card class="vs-con-loading__container" id="loading-container">
        <div
            slot="no-body"
            :style="{
                background: `rgba(var(--vs-primary),.7)`,
            }"
        >
            <div class="vx-row no-gutter justify-center p-12">
                <div class="text-center">
                    <h1 class="text-center font-bold text-white">
                        <feather-icon
                            :icon="'SendIcon'"
                            class="p-3 inline-flex rounded-full"
                            :class="[`text-white`, { 'mb-4': true }]"
                            :style="{
                                background: `rgba(var(--vs-primary),.4)`,
                            }"
                        ></feather-icon>
                        Send Voucher
                    </h1>
                </div>
            </div>
            <div class="vx-row no-gutter w-full">
                <div class="vx-col w-full d-theme-dark-bg p-12">
                    <div v-show="show.single" class="mb-3">
                        <form @keyup.enter="send">
                            <div class="vx-row">
                                <div class="vx-col w-full mb-3">
                                    <vs-input
                                        name="email"
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-user"
                                        icon-pack="feather"
                                        label="Name"
                                        v-model="form.name"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.name !== null"
                                        :danger-text="errors.name"
                                        description-text="Single name without suffix"
                                    />
                                </div>
                                <div class="vx-col w-full mb-3">
                                    <vs-input
                                        name="email"
                                        icon-no-border
                                        color="primary"
                                        icon="icon icon-phone"
                                        icon-pack="feather"
                                        label="Mobile"
                                        v-model="form.phone1"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.phone1 !== null"
                                        :danger-text="errors.phone1"
                                    />
                                </div>
                                <div class="vx-col w-full mb-3">
                                    <vs-input
                                        name="email"
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-mail"
                                        icon-pack="feather"
                                        label="Email"
                                        v-model="form.email1"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.email1 !== null"
                                        :danger-text="errors.email1"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-show="!show.single" class="mb-3">
                        <form>
                            <div class="vx-row">
                                <div class="vx-col w-full mb-3">
                                    <vs-input
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-user"
                                        icon-pack="feather"
                                        label="Joint Name"
                                        v-model="form.name"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.name"
                                        :danger-text="errors.name"
                                        description-text="Joint client name e.g. Sally and David Bloggs"
                                    />
                                </div>
                                <div class="vx-col w-1/2 mb-3">
                                    <vs-input
                                        color="primary"
                                        name="email"
                                        icon-no-border
                                        icon="icon icon-phone"
                                        icon-pack="feather"
                                        label="Mobile 1"
                                        v-model="form.phone1"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.phone1 !== null"
                                        :danger-text="errors.phone1"
                                    />
                                </div>
                                <div class="vx-col w-1/2 mb-3">
                                    <vs-input
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-phone"
                                        icon-pack="feather"
                                        label="Mobile 2"
                                        v-model="form.phone2"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.phone2 !== null"
                                        :danger-text="errors.phone2"
                                    />
                                </div>
                                <div class="vx-col w-1/2 mb-3">
                                    <vs-input
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-mail"
                                        icon-pack="feather"
                                        label="Email 1"
                                        v-model="form.email1"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.email1 !== null"
                                        :danger-text="errors.email1"
                                    />
                                </div>
                                <div class="vx-col w-1/2 mb-3">
                                    <vs-input
                                        color="primary"
                                        icon-no-border
                                        icon="icon icon-mail"
                                        icon-pack="feather"
                                        label="Email 2"
                                        v-model="form.email2"
                                        :size="size"
                                        class="w-full"
                                        :danger="errors.email2 !== null"
                                        :danger-text="errors.email2"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="flex mb-6">
                        <vs-switch
                            color="primary"
                            v-model="show.single"
                            class="mb-3 mr-3"
                        >
                        </vs-switch>
                        <span v-if="show.single">Single</span>
                        <span v-else>Mirror</span>
                    </div>
                    <div class="flex justify-center">
                        <vs-button
                            class="w-full xl:w-auto"
                            :size="size"
                            @click="send"
                            :disabled="!isValidated"
                            :type="isValidated ? 'filled' : 'filled'"
                            color="primary"
                            >Send</vs-button
                        >
                    </div>
                </div>
            </div>
            <div
                v-if="quickSendHistory && quickSendHistory.length"
                class="text-white pl-12 pr-12 pt-4 pb-4"
            >
                <div class="flex justify-between items-center">
                    <h4 class="text-white pb-3">Recent History</h4>
                    <vx-tooltip text="Clear History" position="top" delay=".3s">
                        <feather-icon
                            icon="XIcon"
                            svgClasses="h-4 w-4 text-primary"
                            class="ml-1 cursor-pointer"
                            @click="clearHistoryConfirm"
                        />
                    </vx-tooltip>
                </div>
                <ul>
                    <li
                        v-for="(log, index) in quickSendHistory"
                        :key="index"
                        class="mb-6"
                    >
                        <h6 class="text-white">{{ log.name }}</h6>
                        <p class="text-sm">
                            https://wills.mylastwill.co.uk/coupon/{{
                                log.coupon
                            }}
                        </p>
                        <div>
                            <p v-if="log.phone1" class="text-sm">
                                {{ log.phone1 }}
                                <feather-icon
                                    class="ml-1"
                                    :icon="
                                        log.phone1_sent ? 'CheckIcon' : 'XIcon'
                                    "
                                    svgClasses="h-3 w-3 rounded-full text-white bg-primary"
                                ></feather-icon>
                            </p>

                            <p v-if="log.phone2" class="text-sm">
                                {{ log.phone2 }}
                                <feather-icon
                                    class="ml-1"
                                    :icon="
                                        log.phone2_sent ? 'CheckIcon' : 'XIcon'
                                    "
                                    svgClasses="h-3 w-3 rounded-full text-white bg-primary"
                                ></feather-icon>
                            </p>

                            <p v-if="log.email1" class="text-sm">
                                {{ log.email1 }}
                                <feather-icon
                                    class="ml-1"
                                    :icon="
                                        log.email1_sent ? 'CheckIcon' : 'XIcon'
                                    "
                                    svgClasses="h-3 w-3 rounded-full text-white bg-primary"
                                ></feather-icon>
                            </p>

                            <p v-if="log.email2" class="text-sm">
                                {{ log.email2 }}
                                <feather-icon
                                    class="ml-1"
                                    :icon="
                                        log.email2_sent ? 'CheckIcon' : 'XIcon'
                                    "
                                    svgClasses="h-3 w-3 rounded-full text-white bg-primary"
                                ></feather-icon>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'QuickSendCard',
    components: {},
    mixins: [textFormat],
    computed: {
        isValidated() {
            if (
                this.form.name &&
                (this.form.email1 ||
                    this.form.phone1 ||
                    this.form.phone2 ||
                    this.form.email2)
            )
                return true
            return false
        },
        quickSendHistory() {
            return this.$store.getters.quickSendHistory
        },
        size() {
            return this.$store.getters.windowBreakPoint === 'xs'
                ? 'large'
                : null
        },
    },
    data() {
        return {
            form: {
                name: null,
                phone1: null,
                phone2: null,
                email1: null,
                email2: null,
            },
            errors: {
                name: null,
                phone1: null,
                phone2: null,
                email1: null,
                email2: null,
            },
            scrollSettings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
            show: {
                single: true,
            },
        }
    },
    methods: {
        send() {
            if (!this.isValidated) return this.validateForm()
            this.$vs.loading({
                color: 'primary',
                container: '#loading-container',
                scale: 0.45,
            })
            this.clearErrors()
            this.form.code = this.$store.getters.accessCode.code
            http.post('quick_send', this.form)
                .then((response) => {
                    this.$store.commit('quickSendHistoryAdd', response.data)
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code sent',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-send',
                        position: 'top-center',
                    })
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                    this.clearForm()
                })
                .catch((error) => {
                    this.handleErrors(error.response.data)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                })
        },
        validateForm() {},
        clearErrors() {
            this.errors.name = null
            this.errors.phone1 = null
            this.errors.phone2 = null
            this.errors.email1 = null
            this.errors.email2 = null
        },
        clearForm() {
            this.form.name = null
            this.form.phone1 = null
            this.form.phone2 = null
            this.form.email1 = null
            this.form.email2 = null
        },
        handleErrors(errors) {
            if (errors.name) this.errors.name = errors.name[0]
            if (errors.phone1) this.errors.phone1 = errors.phone1[0]
            if (errors.phone2) this.errors.phone2 = errors.phone2[0]
            if (errors.email1) this.errors.email1 = errors.email1[0]
            if (errors.email2) this.errors.email2 = errors.email2[0]
        },
        clearHistoryConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Confirm`,
                text: 'Clear recent send history?',
                accept: this.clearHistory,
                acceptText: 'Clear',
            })
        },
        clearHistory() {
            this.$store.commit('quickSendHistoryClear')
        },
    },
}
</script>

<style scoped>
.max {
    max-height: 320px;
}
</style>
