<template>
    <div class="container">
        <div class="vx-row mb-base">
            <div class="vx-col hidden md:block w-full md:w-1/2 mb-base">
                <vx-card class="bg-transparent no-shadow mb-base">
                    <h2 class="">Client <strong>Tools</strong></h2>
                    <!--
                    <p>
                        We have now launched our new quick send and broker
                        manager area. You will find the experience is more
                        unified, simpler with improved features.
                    </p>
                    -->
                    <p class="pt-4">
                        With the access code
                        <span class="font-bold">{{ accessCode.code }}</span>
                        your clients will be entitled to a
                        <strong> {{ accessCode.discount }}% </strong>discount.
                    </p>
                </vx-card>
            </div>
            <!--

            <div class="vx-col w-full hidden md:block md:w-1/4">
                <vx-card
                    class="h-full bg-transparent no-shadow mb-base flex justify-center items-center"
                >
                    <img
                        class="mx-auto"
                        style="width: 80%"
                        alt="broker logo"
                        :src="accessCode.broker.logo"
                    />
                </vx-card>
            </div>
            -->

            <div class="vx-col hidden md:block w-full md:w-1/4 mb-base">
                <AccessCodeCard />
            </div>
            <div class="vx-col hidden md:block w-full md:w-1/4 mb-base">
                <LoginCard />
            </div>
        </div>

        <div>
            <div class="vx-row">
                <div class="vx-col w-full md:w-1/2 lg:w-1/3 mb-base">
                    <QuickSendCard />
                </div>
                <div class="vx-col w-full md:w-1/2 lg:w-1/3 mb-base">
                    <HandoverCard />
                </div>
                <div class="vx-col w-full md:w-1/2 lg:w-1/3 mb-base">
                    <ReferCard />
                </div>
            </div>
            <!--
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h1 class="pt-12 pb-12">Help</h1>
                    <VideoCard />
                </div>
            </div>
            -->
        </div>
    </div>
</template>

<script>
import QuickSendCard from './cards/QuickSend'
import AccessCodeCard from './cards/AccessCode'
import HandoverCard from './cards/Handover'
// import VideoCard from '@/views/quick/Home/cards/Video'
import ReferCard from '@/views/quick/Home/cards/Refer'
import LoginCard from '@/views/quick/Home/cards/Login'

export default {
    name: 'Home',
    components: {
        LoginCard,
        // VideoCard,
        HandoverCard,
        AccessCodeCard,
        QuickSendCard,
        ReferCard,
    },
    created() {
        this.$store.dispatch('inspectToken')
    },
    computed: {
        accessCode() {
            return this.$store.getters.accessCode
        },
    },
    data() {
        return {}
    },
    mounted() {},
}
</script>
