<template>
    <vx-card actionable class="h-full overflow-hidden">
        <div @click="logout" class="items-center text-center cursor-pointer">
            <feather-icon
                :icon="'LogInIcon'"
                class="p-3 inline-flex rounded-full"
                :class="[`text-primary`, { 'mb-2': true }]"
                :style="{
                    background: `rgba(var(--vs-primary),.15)`,
                }"
            ></feather-icon>

            <div class="mt-3 truncate text-primary">
                <h5 class="mb-1 font-bold text-primary">Login</h5>
                <small style="font-size: 1rem">Full Management Tools</small>
            </div>
        </div>
    </vx-card>
</template>

<script>
export default {
    name: 'LoginCard',
    methods: {
        logout() {
            this.$store.dispatch('logout')
            this.$store.commit('loginTabIndex', 1)
        },
    },
}
</script>
